import dataProvider from "dataProvider";
import { DeliveryType } from "types/enum";

let fetcher = dataProvider("adminInstance");

export const uploadBulkInvoice = async (careHomeId: string, file: File, onProgress: (val: number) => any) => {
    const formData = new FormData();
    formData.append("file", file);

    return await fetcher.post(
        `/invoices/bulk-invoice?careHomeId=${careHomeId}`,
        formData,
        {},
        onProgress,
    );
};


export const getInvoices = async (deliveryType: DeliveryType, careHomeId: string, bulkInvoiceId?: string, page = 1, status?: string, search?: string) => {
    const params = new URLSearchParams({
        deliveryType,
        page: page.toString(),
        perPage: "10",
        careHomeId,
    });
    if (bulkInvoiceId) {
        params.append("bulkInvoiceId", bulkInvoiceId);
    }
    if (status) {
        params.append("status", status);
    }
    if (search) {
        params.append("search", search);
    }
    return fetcher.get(
        `/invoices/single?${params.toString()}`
    ).then(res => res.data);
};

export const getBulkInvoices = async (careHomeId: string) => {
    return fetcher.get(`/invoices/bulk?careHomeId=${careHomeId}`).then(res => res.data);
};

export const getFile = async (key: string) => {
    return fetcher.get(`/files/${key}`).then(res => res.data);
};


export const sendInvoices = async (bulkInvoiceId: string) => {
    return fetcher.put(
        `/invoices/send-email?bulkInvoiceId=${bulkInvoiceId}`, {}
    ).then(res => res.data);
};


export const sendInvoiceById = async (id: string) => {
    return fetcher.put(
        `/invoices/send-email/${id}`, {}
    ).then(res => res.data);
};

export const getSummaryReport = async (id: string) => {
    return fetcher.get(
        `/invoices/bulk/${id}/summary-report`, {}
    ).then(res => res.data);
};

export const deleteBulkInvoice = async (id: string) => {
    return fetcher.delete(
        `/invoices/bulk/${id}`, {}
    ).then(res => res.data);
};
