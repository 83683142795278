import NutritionsPage from "components/feature/Nutritions";


const Nutritions = () => {
  return (
    <>
      <NutritionsPage />
    </>
  );
};

export default Nutritions;
