import { Box } from "@mui/material";
import CareHomeInfoCard from "components/common/CareHomeInfoCard";
import styles from "./styles";
import Button from "components/common/Button";
import AddIcon from "@mui/icons-material/Add";
import { useAppSelector } from "redux/store";
import NutritionList from "./NutritionList";
import React from "react";
import NutritionForm from "./NutritionForm";


const NutritionsPage = () => {
    const [editMode, setEditMode] = React.useState(false);


    const onAdd = () => {
        setEditMode(true);
    }

    return (
        <Box sx={styles.wrapper}>
            <CareHomeInfoCard showToggle={false} />
            <h3>Nutritions</h3>
            <Box sx={styles.content}>
                {editMode ? <NutritionForm onBack={() => setEditMode(false)} /> :
                    <>
                        <Box display='flex' justifyContent="flex-end">
                            <Button
                                label="Add Nutrition"
                                icon
                                sx={styles.btn}
                                onClick={onAdd}
                            >
                                <AddIcon fontSize="small" sx={styles.icon} />
                            </Button>
                        </Box>
                        <NutritionList />
                    </>}
            </Box>
        </Box >
    );
}

export default NutritionsPage;