import dataProvider from "dataProvider";
import { CareHomeSetting } from "types/api";

let fetcher = dataProvider("adminInstance");

export const getSettings = async (careHomeId: string): Promise<CareHomeSetting> => {
    return fetcher.get(
        `/care-home/${careHomeId}/settings`
    ).then(res => res.data?.data?.data);
};

export const updateSettings = async (careHomeId: string, settingData: CareHomeSetting, file?: File): Promise<any> => {
    const formData = new FormData();
    if (file) {
        formData.append("logo", file);
    }
    formData.append("settingData", JSON.stringify(settingData));

    return fetcher.put(
        `/care-home/${careHomeId}/settings`, formData
    ).then(res => res.data);
};
