import dataProvider from "dataProvider";
import { CareHomeSetting, Nutrition } from "types/api";

let fetcher = dataProvider("adminInstance");

export const getNutritions = async (careHomeId: string): Promise<Nutrition[]> => {
    return fetcher.get(
        `/nutritions?careHomeId=${careHomeId}`
    ).then(res => res.data?.data);
};

export type NutritionData = Omit<Nutrition, "attachment" | "id"> & { attachment: File };

export const createNutrition = async (careHomeId: string, data: NutritionData): Promise<any> => {
    const formData = new FormData();
    Object.entries(data).forEach(([key, value]) => {
        formData.append(key, value);
    });

    return fetcher.post(
        `/nutritions?careHomeId=${careHomeId}`, formData
    ).then(res => res.data);
};
