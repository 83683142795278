import { useQuery } from "react-query";
import { useAppSelector } from "redux/store";
import { getNutritions } from "utils/api/nutrition";
import { columns } from "./columns";
import DataGrid from "components/common/DataGrid";



const NutritionList = () => {
    const { pccId } = useAppSelector((state) => state.pccInfo);
    const { data, refetch } = useQuery(
        ["getNutritions", pccId],
        () => getNutritions(pccId),
        {
            retry: 1,
        },
    );

    return <DataGrid
        rows={data || []}
        columns={columns}
        disableSelectionOnClick
        rowsPerPageOptions={[5, 10, 20, 50, 100]}
        pageSize={10}
        rowCount={10}
        loading={false}
        componentsProps={{
            toolbar: { showQuickFilter: true },
        }}
        emptyTable="No Nutrition added yet."
    />
}

export default NutritionList;