import { Box } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import FileLink from "components/feature/Invoices/SingleInvoiceTable/FileLink";
import { NutritionType } from "types/enum";


export const columns: GridColDef[] = [
    {
        field: "name",
        headerName: "Name",
        sortable: true,
        flex: 1,
        headerAlign: "center",
        align: "center",
        renderCell: ({ row }) => <Box fontWeight="bold">{row.name}</Box>
    },
    {
        field: "description",
        headerName: "Description",
        sortable: true,
        flex: 1,
        headerAlign: "center",
        align: "center",
    },
    {
        field: "startDate",
        headerName: "Start Date",
        sortable: true,
        flex: 1,
        headerAlign: "center",
        align: "center",
        renderCell: ({ row }) => <Box>{new Date(row.startDate).toDateString()}</Box>
    },
    {
        field: "endDate",
        headerName: "End Date",
        sortable: true,
        flex: 1,
        headerAlign: "center",
        align: "center",
        renderCell: ({ row }) => <Box>{row.endDate ? new Date(row.endDate).toDateString() : "N/A"}</Box>
    },
    {
        field: "type",
        headerName: "Type",
        sortable: true,
        flex: 1,
        headerAlign: "center",
        align: "center",
        renderCell: ({ row }) => <Box>{row.type === NutritionType.Menu ? "Menu" : "Other"}</Box>
    },
    {
        field: "attachment",
        headerName: "Attachment",
        sortable: true,
        flex: 1,
        headerAlign: "center",
        align: "center",
        renderCell: ({ row }) => <FileLink fileKey={row.attachment} label="Download File" />
    },
]