import { Styles } from "types/common";

const styles: Styles = {
    wrapper: {
        "& a": {
            textDecoration: "underline",
        },
        "& td": {
            textAlign: "center",
            padding: "5.5px",
            border: "none",
            borderRadius: "5px",
        },
        display: "flex",
        height: "calc(100vh - 90px)",
        flexBasis: "100%",
        flexDirection: "column",
    },
    content: {
    },
    btn: {
        fontSize: "14px",
        minWidth: "150px",
        margin: "10px 0",
        height: "28px",
        backgroundColor: "primary.dark",
    },
};

export default styles;
