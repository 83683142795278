import { useMemo, useState } from "react"
import { useMutation, useQuery } from "react-query";
import { useAppSelector } from "redux/store";
import { CareHomeSetting } from "types/api";
import { getSettings, updateSettings } from "utils/api/setting";


interface Params {
    settingData: CareHomeSetting;
}

export const useCurrentSetting = () => {
    const { pccId } = useAppSelector(state => state.pccInfo);

    const { data, refetch, isLoading } = useQuery({
        queryKey: ["getSettings", pccId],
        queryFn: () => getSettings(pccId),
    });

    const currentSettings = useMemo(() => {
        if (data) {
            const {
                modules: moduleValues,
            } = data;
            try {
                return JSON.parse(moduleValues);
            } catch (error) {
                console.log(error);
            }
        }
        return [];
    }, [data]);

    return { data: currentSettings, refetch, isLoading };
}

const useActions = (onSuccess?: any) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const { pccId } = useAppSelector(state => state.pccInfo);


    const { mutate: createModuleSetting } = useMutation(
        ["saveSettings"],
        ({ settingData }: Params) => {
            return updateSettings(pccId, settingData);
        },
        {
            onMutate: () => {
                setError('');
                setLoading(true);
            },
            onSettled: () => {
                setLoading(false);
            },
            onSuccess: (data) => {
                console.log(data);
                onSuccess && onSuccess();
            },
            onError: (err) => {
                if (err["response"].data.message) {
                    setError(err["response"].data.message);
                }
            },
        },
    );


    return { createModuleSetting, loading, error };
}

export default useActions;