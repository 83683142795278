import { Styles } from "types/common";

const styles: Styles = {
    backArrow: {
        marginRight: "10px",
        cursor: "pointer",
    },
    backStyles: {
        display: "flex",
        alignItems: "center",
        fontSize: "15px",
        cursor: "pointer",
        color: "custom.background.dropdownCta",
        top: "10px",
        left: "10px",
        "&:after": {
            top: "20px",
            content: '""',
            display: "block",
            margin: "auto",
            height: "1.5px",
            width: "100%",
            background: "#056EE6",
            position: "absolute",
        },
        "& .MuiSvgIcon-root": {
            fontSize: "12px",
            marginRight: "0",
        },
    },
    formWrapper: {
        padding: "20px",
        marginTop: "20px",
        border: "1px solid #E0E0E0",
        borderRadius: "5px",
    },
    labelPos: {
        display: "flex",
        justifyContent: "start",
        alignItems: "start",
        "& label": {
            width: "110px",
        },
    },
    selectWrapper: {
        flex: 1
    },
    input: {
        visibility: "hidden",
        height: 0,
        width: 0,
    },
    dateSelector: {
        width: "300px"
    },
    fileName: {
        textDecoration: "underline",
        ":hover": {
            cursor: "pointer"
        }
    }
};

export default styles;
