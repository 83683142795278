export enum DeliveryType {
    Email = 'email',
    Postal = 'postal',
    All = 'all'
};

export enum ModuleName {
    CareHomeHub = 'CareHomeHub',
    Medication = 'Medication',
    Reflection = 'Reflection',
    CoreHealth = 'CoreHealth',
    Nutrition = 'Nutrition',
    FamilyWhiteboard = 'FamilyWhiteboard',
}

export enum ModuleSettingType {
    All = 'all',
    CareHome = 'careHome',
    Resident = 'resident',
}

export enum NutritionType {
    Menu = 'menu',
}
