import { Styles } from "types/common";

const styles: Styles = {
    input: {
        visibility: "hidden",
        height: 0,
        width: 0,
        position: "absolute"
    },
    fileName: {
        textDecoration: "underline",
        ":hover": {
            cursor: "pointer"
        }
    },
    del: {
        cursor: "pointer",
        marginLeft: "20px"
    },
};

export default styles;
