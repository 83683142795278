import { useState } from "react"
import { useMutation } from "react-query";
import { useAppSelector } from "redux/store";
import { updateSettings } from "utils/api/setting";
import { useCurrentSetting } from "./actions";


interface Params {
    index: number;
}

const useDeleteActions = (onSuccess?: any) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const { pccId } = useAppSelector(state => state.pccInfo);
    const { data: currentSettings } = useCurrentSetting();

    const { mutate: deleteModuleSetting } = useMutation(
        ["deleteModuleSetting"],
        ({ index }: Params) => {
            return updateSettings(pccId, {
                modules: JSON.stringify(currentSettings.filter((_, i) => i !== index)),
            });
        },
        {
            onMutate: () => {
                setError('');
                setLoading(true);
            },
            onSettled: () => {
                setLoading(false);
            },
            onSuccess: (data) => {
                console.log(data);
                onSuccess && onSuccess();
            },
            onError: (err) => {
                if (err["response"].data.message) {
                    setError(err["response"].data.message);
                }
            },
        },
    );


    return { deleteModuleSetting, loading, error };
}

export default useDeleteActions;