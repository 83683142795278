import { Styles } from "types/common";

const styles: Styles = {
  wrapper: {
    width: "100%",
  },
  module: {
    padding: "5px 10px",
    borderRadius: "5px",
    backgroundColor: "custom.background.lightGray",
    color: "custom.text.secondary",
    fontSize: "12px",
    marginRight: "5px",
    marginBottom: "5px",
  },
  moduleItem: {
    padding: "10px",
    borderRadius: "5px",
    backgroundColor: "custom.background.gray",
    marginBottom: "10px",
  },
};

export default styles;
