import { Box } from "@mui/material";
import Button from "../Button";
import styles from "./styles";
import { ChangeEvent, useRef } from "react";

interface Props {
    file?: File | File[];
    onChange?: (file: File | File[]) => any;
    multiple?: boolean;
    label: string;
    accept?: string;

}

const FileUploader = (props: Props) => {
    const { file, onChange, label, multiple, accept } = props;
    const ref = useRef<HTMLInputElement>(null);

    const onFileChange = (event: ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files;
        console.log(files);
        if (files.length > 5) {
            alert("Only allow maximum 5 attachments");
            return;
        }
        if (multiple) {
            const fileItems: File[] = [];
            for (let index = 0; index < files.length; index++) {
                fileItems.push(files[index]);
            }
            onChange(fileItems);
        } else {
            onChange(files[0]);
        }
    }

    const onDelete = (index: number) => {
        onChange((file as File[])?.filter((item, idx) => index !== idx));
    }

    return <Box>
        <Box component={"input"} type="file" multiple={multiple} accept={accept || "application/pdf"} ref={ref} sx={styles.input} id="nutrition-attachment" onChange={onFileChange} />
        <Box display="flex" >
            <Box fontWeight="bold" fontSize={15} width={110}>{label}</Box>
            <Box>
                <Button label={multiple ? "Upload files" : "Upload File"} size="small" onClick={() => ref.current.click()} />
                {multiple ?
                    <Box>
                        {(file as File[])?.map((item, index) => <Box key={item.name} display="flex" justifyContent="space-between" alignItems="center" mt={1}>
                            <Box sx={styles.fileName}>{item.name}</Box>
                            <Box
                                sx={styles.del}
                                component="img"
                                src="/icons/del-bin.svg"
                                alt="del-icon"
                                onClick={() => {
                                    onDelete(index)
                                }}
                                tabIndex={0}
                            />
                        </Box>)}
                    </Box> :
                    <Box sx={styles.fileName}>{(file as File)?.name}</Box>}
            </Box>
        </Box>
    </Box>;
}

export default FileUploader;