import { useState } from "react";
import { useMutation } from "react-query";
import { useAppSelector } from "redux/store";
import { createNutrition, NutritionData } from "utils/api/nutrition";

interface Params {
    nutrition: NutritionData;
}

const useActions = (onSuccess?: any) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const { pccId } = useAppSelector(state => state.pccInfo);


    const { mutate: handleCreate } = useMutation(
        ["createNutrition"],
        ({ nutrition }: Params) => {
            return createNutrition(pccId, nutrition);
        },
        {
            onMutate: () => {
                setError('');
                setLoading(true);
            },
            onSettled: () => {
                setLoading(false);
            },
            onSuccess: (data) => {
                console.log(data);
                onSuccess && onSuccess();
            },
            onError: (err) => {
                if (err["response"].data.message) {
                    setError(err["response"].data.message);
                }
            },
        },
    );


    return { handleCreate, loading, error };
}

export default useActions;