import { Box } from "@mui/system";
import styles from "./styles";
import Button from "components/common/Button";
import Steppers, { Step } from "components/common/Steppers";
import { useMemo, useState } from "react";
import Uploader from "./Uploader";
import useActions from "./actions";
import { LinearProgress, linearProgressClasses, styled } from "@mui/material";
import { useAppSelector } from "redux/store";
import EmailInvoices from "./EmailInvoices";
import PostalInvoices from "./PostalInvoices";
import PopUp from "components/common/PopUp";
import SummaryReport from "./SummaryReport";


const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
  },
}));

const UploadResult = ({ emails, postals, errors }: { emails: number, postals: number, errors: any[] }) => {
  const [showDetailPopup, setShowPopup] = useState(false);

  return <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
    <Box fontWeight="bold">Bulk Invoice successfully split</Box>
    <Box>{emails} invoices for Email Delivery</Box>
    <Box>{postals} invoices for Postal Delivery</Box>
    <Box display="flex" flexDirection="row" alignItems="center">
      <Box>{errors.length} invoices are not matching with resident data in Gerry </Box>
      <Box sx={styles.detail} onClick={() => setShowPopup(true)}>Show detail</Box>
    </Box>
    {!!showDetailPopup && (
      <PopUp
        open={!!showDetailPopup}
        handleClose={() => setShowPopup(false)}
        heading="List of residents not matching"
        actionLeft={{}}
        actionRight={{}}
        handleRightBtnClick={() => setShowPopup(false)}
      >
        <Box maxHeight={500} overflow="auto">
          {errors.map(errorInvoice => <Box key={errorInvoice.firstName}>
            <Box sx={styles.errorTitle}>Resident: {errorInvoice.firstName} , {errorInvoice.lastName}</Box>
            <Box sx={styles.errorMessage}>Error: {errorInvoice.message || "Unknown"}</Box>
          </Box>)}
        </Box>
      </PopUp>
    )}
    <Box alignSelf="flex-start" fontSize="13px" maxWidth="400px" marginTop="12px">Please note that if a valid email for a Finance POA is not found, the respective invoice is automatically marked for postal delivery.</Box>
  </Box>
}

interface Props {
  onBack: () => any;
}

const steps: Step[] = [
  { value: "1", label: "Upload Bulk Invoice" },
  { value: "2", label: "Send Email Delivery Invoices" },
  { value: "3", label: "Print Postal Delivery Invoices" },
  { value: "4", label: "Download Summary Report" },
]

const SplitBulkInvoice = ({ onBack }: Props) => {
  const [step, setStep] = useState(1);
  const [file, setFile] = useState<File>(null);
  const { uploadInvoice, loading, progress, data } = useActions();
  const { emails, postals, bulkInvoice, errors, postalFile } = data || {};
  const { pccId } = useAppSelector((state) => state.pccInfo);
  const actionButtonLabel = useMemo(() => {
    if (step === 1) {
      if (data) {
        return "Next Step";
      } else {
        return "Split Invoice";
      }
    }
    if (step === 4) {
      return "Finish";
    }
    return "Next Step";
  }, [step, data]);

  const onSubmit = () => {
    if (step === 4) {
      onBack();
      return;
    }
    if (data) {
      setStep(step + 1);
    } else {
      uploadInvoice({ file, careHomeId: pccId });
    }
  }


  return (
    <Box sx={styles.wrapper}>
      <Button label="Back to Invoices" as="BackButton" onClick={onBack} />
      <Box marginTop="16px">
        <Steppers steps={steps} currentStep={step} />
        <Box marginTop="30px" marginBottom="30px">
          {step === 1 && (!loading ?
            (
              data ? <UploadResult emails={emails?.length} postals={postals?.length} errors={errors || []} /> :
                <Uploader onFileChange={setFile} />
            ) :
            <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
              <Box>Uploading...</Box>
              <BorderLinearProgress variant="determinate" value={progress} />
            </Box>)}
          {step === 2 && <EmailInvoices bulkInvoiceId={bulkInvoice?.id} />}
          {step === 3 && <PostalInvoices bulkInvoiceId={bulkInvoice?.id} postalFile={postalFile} />}
          {step === 4 && <SummaryReport bulkInvoiceId={bulkInvoice?.id} />}
        </Box>
        <Box display="flex" justifyContent="flex-end">
          <Button label={actionButtonLabel} disabled={loading} onClick={onSubmit} />
        </Box>
      </Box>
    </Box>
  );
};

export default SplitBulkInvoice;
